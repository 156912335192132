@import '../../../css/variables';

.contact_module {
  background: $color-secondary;
  padding: 5rem 0;

  &__titel {
    @include h2;

    text-align: center;
    color: $color-tertiary;
    margin-bottom: 1rem;

    @include font-inherit;
    @include no-spacing;
  }

  &__text {
    @include p--medium;
    text-align: center;
    color: $color-tertiary;
    margin: 0;
    font-family: $basic-font;
    
    @include font-inherit;
    @include no-spacing;
  }

  &__form_inputs {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
  }

  &__input {
    font-size: 1rem;
    padding: 0.5rem 0.7rem;
    border: none;
    background: transparent;
    color: $color-tertiary;
    border-bottom: 2px solid $color-tertiary;
    margin-bottom: 1rem;
    font-family: $basic-font;
    outline: none;
  
    &::placeholder {
      color: $color-tertiary;
    }

    &:focus {
      outline: none;
      border-color: $color-tertiary;
    }
  }

  &__textarea-wrapper {
    overflow: hidden;
  }

  &__textarea {
    font-size: 1rem;
    padding: 0.5rem 0.7rem;
    border: none;
    background: transparent;
    color: $color-tertiary;
    width: 100%;
    max-width: 100%;
    background-image: linear-gradient($color-tertiary 2px, transparent 2px);
    background-size: 100% 50px;
    line-height: 50px;
    font-family: $basic-font;
    transform: translateY(-8px);

    &:focus {
      outline: none;
      background-image: linear-gradient($color-tertiary 2px, transparent 2px);
    }
  
    &::placeholder {
      color: $color-tertiary;
    }
  }

  &__submit {
    @include button($color-secondary, $color-tertiary);
    margin-top: 1rem;
  }

  &__berichten {
    margin-top: 2rem;
    color: $color-tertiary;
    text-align: center;
  }
}